const APP_STATUS = {
	LOADING: 'LOADING',
	ERROR: 'ERROR',
	SUCCESS: 'SUCCESS',
};

const ACTION_TYPE = {
	SETUP: 'SETUP',
	UPDATE: 'UPDATE',
};

const STAGE = {
	LOCAL: 'LOCAL',
	DEVELOPMENT: 'DEVELOPMENT',
	TESTING: 'TESTING',
	STAGING: 'STAGING',
	PRODUCTION: 'PRODUCTION',
};

export {
  ACTION_TYPE,
	APP_STATUS,
	STAGE
};