import React from 'react';
import { reportError } from '../models/analytics';

class ErrorBoundary extends React.Component {
	state = { hasError: false };

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		// log here for any partner devs to see what's up.
		// eslint-disable-next-line no-console
		console.error(error, errorInfo);
		reportError(error, errorInfo);
	}

	render() {
		if (this.state.hasError) {
			// default fallback is null which will be blank
			return this.props.fallback || null;
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
