import React from 'react';
import ReactMarkdown from 'react-markdown';

const defaultText = 'By providing your card information, you allow Buddy Technology, Inc. to charge your card for future payments in accordance with their terms.'

const DisclosureText = ({ disclosureText = defaultText }) => {
  if (!Array.isArray(disclosureText)) {
    return (
      <p className='disclosure' id='text-1'>{disclosureText}</p>
    );
  }
  return (
    <div>{disclosureText.map((text, index) => <ReactMarkdown key={text[0]} components={{ p: ({ node, ...props }) => <p {...props} className='disclosure' id={`text-${index}`}></p>}}>{text}</ReactMarkdown>)}</div>
  );
};

export default DisclosureText;