/* eslint-disable no-undef */
const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
const VERSION = process.env.REACT_APP_VERSION;
const ENV = process.env.REACT_APP_SENTRY_ENV;

/**
 * Loads Sentry for error tracking
 */
export const loadSentry = () => {
	const asyncSentry = document.createElement('script');
	asyncSentry.src = `https://js.sentry-cdn.com/${SENTRY_DSN}.min.js`;
	asyncSentry.crossOrigin = 'anonymous';
	asyncSentry['data-lazy'] = 'no';
	asyncSentry.id = 'sentry-async-script';
	// eslint-disable-next-line no-undef
	asyncSentry.onload = () => Sentry.onLoad(() => {
		// eslint-disable-next-line no-undef
		Sentry.init({
			environment: ENV,
			debug: ENV === 'development',
			release: `buddy-payment-element@${VERSION}`,
		});
	});
	asyncSentry.async = true;
	const head = document.querySelector('head');
	const scripts = head.getElementsByTagName('script');
	// this puts Sentry as the very first script.
	head.insertBefore(asyncSentry, scripts[0]);
};

export const reportError = (error) => {
	const { Sentry } = window;
	Sentry?.captureException(error);
};
