import React from 'react';

// Default the Call To Action Button to setup
const buttonDefaultCopy = 'Setup';

const CheckoutButton = ({options}) => {

  // options object
  const {
    isLoading,
    stripe,
    elements,
    ctaButtonText = buttonDefaultCopy,
    customerSuccess
  } = options

  return (
    <button type="submit" disabled={customerSuccess || isLoading || !stripe || !elements} id="submit">
    <span id="button-text">
      {isLoading ? <div className="spinner" id="spinner" /> : ctaButtonText}
    </span>
  </button>
  );
};

export default CheckoutButton;