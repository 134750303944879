/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
	PaymentElement,
	useStripe,
	useElements,
	LinkAuthenticationElement,
} from '@stripe/react-stripe-js';
import { useMeasure } from 'react-use';

import DisclosureText from './DisclosureText'
import CheckoutButton from './CheckoutButton';

const hostUrl = process.env.REACT_APP_HOST_URL;

function CheckoutForm(
	{
		options = {},
		parent,
	},
) {
	const stripe = useStripe();
	const elements = useElements();

	// Retrieve data object, disclosureText and ctaButtonText from the options object
	const {
		data: {
			email,
			firstName,
			lastName,
			address: {
				country
			} = {},
			address,
			phone,
			dob,
		} = {},
		disclosureText,
		ctaButtonText,
	} = options;

	// Create a customers full name
	const createFullName = (first, last) => `${first} ${last}`;

	// set to name to be used when communicating with stripe
	const name = createFullName(firstName, lastName);

	const [message, setMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [inputEmail, setInputEmail] = useState('');
	const [customerSuccess, setCustomerSuccess] = useState(false);
	const [ref, { height }] = useMeasure();

	// Create our post message with information about the payment element
	const sendMessage = (action, payload) => {
		const source = parent || window.parent || window; // need to access our parent if no source.
		source.postMessage({
			action,
			payload,
			buddy_source: 'payment-element',
			timestamp: Date.now(),
		}, '*');
	};


	// Resize if needed
	useEffect(() => {
		sendMessage('RESIZE', height);
	}, [height]);

	// Handle onSuccessCallBack
	const handleOnSuccessCallback = (payload) => {
		sendMessage('ON_SUCCESS_CALLBACK', payload);
	};

	// Handle onErrorCallback
	const handleOnErrorCallback = (payload) => {
		sendMessage('ON_ERROR_CALLBACK', payload);
	};

	// Handle submit once customer clicks confirm
	const handleSubmit = async (e) => {
		e.preventDefault();

		// Make sure stripe has loaded
		if (!stripe || !elements) {
			return;
		}

		// Set is loading to true
		setIsLoading(true);

		// Confirm payment setup was completed
		const { error, setupIntent } = await stripe.confirmSetup({
			elements,
			confirmParams: {
				payment_method_data: {
					billing_details: {
						name,
						email: email || inputEmail,
						address: {
							country: country
						}
					},
				},
			},
			redirect: 'if_required',
		});

		// if there is a card error, let the customer know
		if (error) {
			if (error?.type === 'card_error' || error?.type === 'validation_error') {
				setIsLoading(false)
				return setMessage(error.message);
			}
		}

		// set the data object
		const data = {
			email: email || inputEmail,
			firstName,
			lastName,
			// this is to prevent an empty address object from going to the database
			...(address && { address: address }),
			phone,
			dob,
			stripePaymentMethodId: setupIntent?.payment_method,
		};

		// create the customer
		try {
			await createCustomer(data, setupIntent?.payment_method);
		} catch (error) {
			// handle call back error
			handleOnErrorCallback({ ok: false, error: error.message });
			return Promise.resolve();
		}
	};

	// create the customer from the API
	const createCustomer = async (data, paymentMethod) => {
		// We only need this for the Payment Element, in the future we may include it
		delete data.address?.country;
		
		// Create a stripe customer using email, name and confirmed paymentMethod id
		try {
			const response = await fetch(`${hostUrl}/`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(data),
			});

			// await the response
			const json = await response.json();

			// if there is data, do some checks
			if (json.ok) {
				// set customer success to true
				setCustomerSuccess(true);

				// Let the customer know if the confirmation was successful
				setMessage('Confirmation successful!');

				// set setIsLoading to false
				setIsLoading(false);

				// call back function
				return handleOnSuccessCallback(json);
			} else {
				throw new Error(json.message || 'There was an issue with that confirmation.');
			}
		} catch (_error) {
			// Let the customer know if there was an error
			setMessage('Confirmation not successful. Please try again.');

			// set is loading to false
			setIsLoading(false);

			// send error to Sentry
			return Promise.reject(_error);
		}
	}

	// Set the stripe form appearance and default values
	// Note: If no email is provided, an email element will appear in the checkout
	// and a customer will need to submit their email manually
	const paymentElementOptions = {
		layout: 'tabs',
		terms: {
			card: 'never',
		},
		defaultValues: {
			billingDetails: {
				name,
				email: email || inputEmail,
				address: {
					country: country,
				}
			},
		},
		fields: {
			billingDetails: {
				name: name ? 'never' : 'auto',
				email: 'never',
			},
		},
	};

	// Set options on the checkout button
	const buttonOptions = {
		stripe,
		elements,
		isLoading,
		ctaButtonText,
		customerSuccess
	}

	// Return the Stripe payment element, Payment terms and email element if no email was provided
	return (
		<div ref={ref}>
			<form onSubmit={handleSubmit}>
				{!email ? <LinkAuthenticationElement id="link-authentication-element" onChange={(event) => { setInputEmail(event.value.email); }} /> : <div />}
				<PaymentElement
					id="payment-element"
					options={paymentElementOptions}
				/>
				<DisclosureText disclosureText={disclosureText} />
				<CheckoutButton options={buttonOptions} />
				{/* Show any error or success messages */}
				{customerSuccess ? <div id="payment-message-success">{message}</div> : <div id="payment-message-error">{message}</div>}
			</form>
		</div>
	);
}

export default CheckoutForm;
