/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './components/CheckoutForm';
import Loading from './components/Loading';
import ErrorBoundary from './components/ErrorBoundary';

import './App.css';

import { ACTION_TYPE, APP_STATUS } from './models/Dictionary';
import { loadSentry } from './models/analytics';

// Stripe key
const defaultStripeKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

// Set the default stage to either DEVELOPMENT | STAGING | PRODUCTION
const defaultStage = process.env.REACT_APP_ACTIVE_ENV.toUpperCase();

// Set the url
const hostUrl = process.env.REACT_APP_HOST_URL;

const { origin, pathname, search } = window.location;

export default function App() {
	// Set client secret
	const [clientSecret, setClientSecret] = useState('');
	const [parent, setParent] = useState();
	const [stripe, setStripe] = useState();
	const [status, setStatus] = useState(APP_STATUS.LOADING);
	// TODO: this error is not being set any where
	const [error,] = useState();
	const [optionProps, setOptionProps] = useState({})


	// Update the props to include the meta object or update it with relevant information
	const updatePaymentProps = (options = {}) => {
		const meta = options.meta || {};
		// Force channelUrl into the meta object in case we need a reverse attribution of a sale.
		const urlPropsToAdd = {
			channelUrl: `${origin}${pathname}`, // Not using href bc we don't want query string included here to make data filtering easier.
			queryString: search,
		};

		Object.assign(meta, urlPropsToAdd);
		// The point is to mutate our argument, so we're ok with param reassignment.
		// eslint-disable-next-line no-param-reassign
		options.meta = meta;
		// log meta for quicker confirmation of meta props in live implementations
		// eslint-disable-next-line no-console
		console.log('This implementation is using the following meta props:', meta);
		setOptionProps(options);
	};

	// Create the post message
	const setupPostMessage = async (event) => {
		const { data: { action, payload = {} }, source } = event;

		// If action is setup or update - create a stripe instance
		if ([ACTION_TYPE.SETUP, ACTION_TYPE.UPDATE].includes(action)) {
			setParent(source);
			const stripeKey = payload.stripeKey || defaultStripeKey;
			const stripeInstance = await loadStripe(stripeKey);
			setStripe(stripeInstance);
			updatePaymentProps(payload);
		}
	};

	// Load sentry and listen for any post messages
	useEffect(() => {
		// load critical 3rd-party code on mount.
		if (!['TESTING', 'DEVELOPMENT'].includes(defaultStage)) {
			loadSentry();
		}

		// event listener to listen for setup post messages
		window.addEventListener('message', setupPostMessage);
		return () => {
			// remove that event listener
			window.removeEventListener('message', setupPostMessage);
		};
	}, []);

	// Render the Stripe checkout and retrieve the client secret
	useEffect(() => {
		fetch(`${hostUrl}/`, {
			method: 'GET',
			headers: { 'Content-Type': 'application/json', 'Accept': '*/*', 'Connection': 'keep-alive' },
		})
			.then((res) => res.json())
			.then((data) => setClientSecret(data.clientSecret))
			.then(() => setStatus(APP_STATUS.SUCCESS))
	}, []);

	// Set stripe appearance
	const appearance = {
		theme: 'flat',
		variables: {
			fontWeightNormal: '500',
			borderRadius: '2px',
			colorBackground: 'white',
			colorPrimary: '#1C4063',
			colorPrimaryText: 'white',
		},
		rules: {
			// TODO: Meaghan, bring in the payment element css located in App.css here. This will make exporting to the theme api easier later
			'.Label': {
				marginBottom: '6px'
			},
			'.Tab, .Input, .Block': {
				boxShadow: '0px 1px 4px rgba(18, 42, 66, 0.08)',
				padding: '12px'
			}
		}
	};

	// Set options for the checkout form
	const stripeOptions = {
		clientSecret,
		appearance,
	};

	// Depending on the applications status, we will either throw a loading window, an error or a successful stripe payment element
	const STATUS = {
		LOADING: <Loading />,
		ERROR: <div className='text-center my-5 text-red-500'>{error || 'An unknown error occurred'}</div>,
		SUCCESS: (
			<div className="App">
				{clientSecret && (
					<Elements options={stripeOptions} stripe={stripe}>
						<CheckoutForm options={optionProps} parent={parent} stage={defaultStage} />
					</Elements>
				)}
			</div>
		)
	}

	return <ErrorBoundary>{STATUS[status] ?? STATUS.ERROR}</ErrorBoundary>;
}
